<div class="page">
	<app-page-header header="Orders">
		<button pButton (click)="exportOrders()">
			<span class="material-symbols-rounded"> download </span>
			Export As CSV
		</button>
	</app-page-header>

	<div class="category-container">
		<div class="p-4 rounded shadow-md border border-[#c4c4c4] mt-4">
			<p class="font-semibold text-lg mb-4">Filters</p>
			<div class="flex gap-2 items-center">
				<div class="flex-grow flex gap-2">
					<app-field-with-label title="Customer Name">
						<input pInputText [(ngModel)]="filterParams.customer_name" placeholder="Enter a Name" />
					</app-field-with-label>

					<app-field-with-label title="Enter a date range">
						<p-calendar [(ngModel)]="filterParams.dates" selectionMode="range" [readonlyInput]="true"
							placeholder="Select a Date"></p-calendar>
					</app-field-with-label>
				</div>

				<div class="flex flex-col gap-2 justify-between">
					<button type="button" pButton (click)="filterOrders()">
						Apply Filter
					</button>

					<button type="button" pButton (click)="clearFilters()">
						Clear Filters
					</button>
				</div>
			</div>
		</div>

		<app-simple-table [columns]="columns" [data]="orders" [paginator]="true" [showFilter]="false"
			[showActions]="false" [onRowClick]="orderDetails">
		</app-simple-table>

		<!-- <table mat-table [dataSource]="orders" matSort>
			<ng-container matColumnDef="display_id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Order Id</th>
				<td mat-cell *matCellDef="let element; let i = index">{{ element.display_id }}</td>
			</ng-container>

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
				<td mat-cell *matCellDef="let element">{{ element.name }}</td>
			</ng-container>

			<ng-container matColumnDef="created_at">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Order Date</th>
				<td mat-cell *matCellDef="let element">
					{{ element.created_at | date : 'MMM, dd YYYY @ hh:mm a' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="total">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Order Total</th>
				<td mat-cell *matCellDef="let element">${{ element.total | number : '1.2-2' }}</td>
			</ng-container>

			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
				<td mat-cell *matCellDef="let element">
					<span class="text-white px-4 py-2 rounded-full font-semibold" [ngClass]="{'not-active': element.status == 'PAYMENT_PENDING',
						'active': element.status == 'PAYMENT_COMPLETED'}">
						{{ element.status }}
					</span>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="orderColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: orderColumns; let element"
				[routerLink]="['/order-details', element.id]" style="cursor: pointer"></tr>
		</table> -->
	</div>
</div>