<div class="page">
	<app-page-header header="Shipping Cost"></app-page-header>
	<div class="add-product-container">
		<form [formGroup]="shippingChargesFrom" (ngSubmit)="saveShippingCharges()">
			<div class="border-b border-gray-300 py-2">
				<div class="flex items-center justify-end mb-4">
					<button pButton type="button" class="w-auto" (click)="addVariant()">
						<span class="material-symbols-rounded"> add </span> New Cost
					</button>
				</div>
				<div class="">
					<!-- <div class="field-title-container">
						<p>No of Seed:</p>
						<span>Add seeds per pack(visible)</span>
					</div> -->
					<div class="form-field-container">
						<!-- <div class="product-variant">
							<div class="product-variant-title">
								<span>Start Weight</span>
								<span>End Weight</span>
								<span>Cost($)</span>
							</div>
							<div class="product-variant-title">
								<span>Action</span>
							</div>
						</div> -->
						<div *ngFor="let group of variantArray?.controls; let i = index" formArrayName="shippingCharges"
							 class="product-variant py-2">
							<div class="product-variant-fields" [formGroupName]="i">
								<input type="hidden" formControlName="id" hidden />
								<app-field-with-label title="Start Weight">
									<p-inputNumber formControlName="start_weight" placeholder="Start Weight" [required]="true"
												   thousandSeparator="," decimalMarker="." inputmode="numeric" />
								</app-field-with-label>

								<app-field-with-label title="End Weight">
									<p-inputNumber formControlName="end_weight" placeholder="End Weight" [required]="true"
												   mask="99*" />
								</app-field-with-label>

								<app-field-with-label title="Cost">
									<p-inputNumber formControlName="cost" placeholder="Cost" [required]="true" thousandSeparator="," decimalMarker="." inputmode="numeric" />
								</app-field-with-label>
							</div>
							<div class="variant-action-container">
								<button pButton type="button" (click)="removeShippingCharge(i)">
									<span class="material-symbols-rounded">delete</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="flex mt-2 justify-end">
				<button pButton type="submit">
					Save
				</button>
			</div>
		</form>
	</div>
</div>