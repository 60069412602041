import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { HomeRoutingModule } from './home-routing-module';
import { RouterModule } from '@angular/router';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { StatisticsCardComponent } from 'src/app/components/statistics-card/statistics-card.component';

@NgModule({
	declarations: [HomeComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(HomeRoutingModule),
		PageHeaderComponent,
		StatisticsCardComponent
	],
})
export class HomeModule { }
