import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { FieldWithLabelComponent } from '../field-with-label/field-with-label.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: 'app-individual-email-dialog',
	templateUrl: './individual-email-dialog.component.html',
	styleUrls: ['./individual-email-dialog.component.scss'],
	imports: [
		CommonModule,
		PageHeaderComponent,
		ReactiveFormsModule,
		CKEditorModule,
		AutoCompleteModule,
		FieldWithLabelComponent,
		InputTextModule,
		ButtonModule,
	],
})
export class IndividualEmailDialogComponent {
	emailFrom: FormGroup;
	editor: any = ClassicEditor;
	allEmails: string[] = [];
	allEmailsCopy: string[] = [];

	@ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;

	constructor(
		private utilityService: UtilityService,
		private ref: DynamicDialogRef,
		private dialogConfig: DynamicDialogConfig,
	) {
		this.emailFrom = new FormGroup({
			emails: new FormControl([]),
			emailSubject: new FormControl('', [Validators.required]),
			emailContent: new FormControl('', [Validators.required]),
		});

		const { emails } = this.dialogConfig.data;
		if (emails) {
			this.allEmails = [...emails];
			this.allEmailsCopy = [...emails];

		}
	}

	get emails() {
		return this.emailFrom.get('emails');
	}
	get emailSubject() {
		return this.emailFrom.get('emailSubject');
	}
	get emailContent() {
		return this.emailFrom.get('emailContent');
	}

	sendEmail() {
		if (this.emailFrom.valid) {
			this.utilityService.showLoading();
			let { emails, emailSubject, emailContent } = this.emailFrom.value;
			const params = {
				emails: emails,
				email_subject: emailSubject,
				email_body: emailContent,
			};
			this.ref.close(params);
		} else {
			this.utilityService.showSuccessToast('Required fields are missing.');
			this.emailFrom.markAllAsTouched();
		}
	}

	searchUser(event: AutoCompleteCompleteEvent) {
		let filtered: any[] = this.allEmailsCopy.filter((email: any) => {
			return email.toLowerCase().indexOf(event.query.toLowerCase()) > -1
		})
		this.allEmails = filtered;
	}
}
