<div class="page">
	<app-page-header header="Invite Code">
		<button pButton (click)="createNewInviteCode()">
			New Invite Code
		</button>
	</app-page-header>

	<div class="category-container">
		<app-simple-table [columns]="columns" [data]="inviteCodeList" [paginator]="true" [showFilter]="false" [showActions]="true" [showEditAction]="true" [showDeleteAction]="true" [onEdit]="editInviteCode" [onDelete]="removeInviteCode">
		</app-simple-table>
	</div>
</div>