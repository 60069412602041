<div class="page">
	<app-page-header header="Shipping Manager"></app-page-header>
	<div class="pt-4">
		<form [formGroup]="shippingFrom" (ngSubmit)="saveShippingManagerEmail()">
			<app-field-with-label title="Shipping Manager Email" [required]="true">
				<input pInputText formControlName="shipping_manager_email" [required]="true" />
				<span
					*ngIf="shippingManagerEmail.invalid && (shippingManagerEmail.dirty || shippingManagerEmail?.touched)">
					<span *ngIf="shippingManagerEmail?.errors?.['required']">
						Shipping manager email is required
					</span>
					<span *ngIf="shippingManagerEmail?.errors?.['email']">
						Invalid shipping manager email
					</span>
				</span>
			</app-field-with-label>
			<div class="flex mt-2 justify-end">
				<button pButton type="submit" pTooltip="Save Shipping manager">
					Save
				</button>
			</div>
		</form>
	</div>
</div>