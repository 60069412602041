import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ProgressEventService {
	@Output() loader: EventEmitter<boolean> = new EventEmitter();
	@Output() uploadProgress: EventEmitter<number> = new EventEmitter();

	constructor() {}
}
