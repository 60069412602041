<div class="page">
	<form [formGroup]="categoryFrom" (ngSubmit)="submitCategoryData()">
		<div class="flex flex-col gap-4">
			<app-field-with-label title="Category Name" [required]="true">
				<input type="text" pInputText formControlName="category_name" [required]="true" />
				<span *ngIf="category_name.invalid && (category_name.dirty || category_name?.touched)">
					<span *ngIf="category_name?.errors?.['required']"> Category name is required </span>
				</span>
			</app-field-with-label>

			<app-field-with-label title="Category Code" [required]="true">
				<input type="text" pInputText formControlName="category_code" [required]="true" />
				<span *ngIf="category_code.invalid && (category_code.dirty || category_code?.touched)">
					<span *ngIf="category_code?.errors?.['required']"> Category code is required </span>
				</span>
			</app-field-with-label>

			<app-field-with-label title="Category Code" [required]="true">
				<textarea pInputTextarea name="category_description" placeholder="Category Description"
					formControlName="category_description" required></textarea>

				<span
					*ngIf="category_description.invalid && (category_description.dirty || category_description?.touched)">
					<span *ngIf="category_description?.errors?.['required']">
						Category Description is required
					</span>
				</span>
			</app-field-with-label>

			<app-field-with-label title="Category Position" [required]="true">
				<p-dropdown formControlName="position" [options]="categoryPosition" optionLabel="title"
					optionValue="value" placeholder="Select a City"></p-dropdown>
				<span *ngIf="position.invalid && (position.dirty || position?.touched)">
					<span *ngIf="position?.errors?.['required']"> Category Position is required </span>
				</span>
			</app-field-with-label>

			<div class="flex justify-end">
				<button pButton type="submit">Save Category</button>
			</div>
		</div>
	</form>
</div>