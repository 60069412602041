<div class="page">
	<app-page-header header="Settings"></app-page-header>

	<div>
		<div class="form-field">
			<div class="field-title-container">
				<p>About Us:</p>
				<span>Change the about us text for the website</span>
			</div>
			<div class="form-field-container">
				<ckeditor [editor]="editor" [(ngModel)]="aboutUsData.text"></ckeditor>
				<button pButton pTooltip="Save About us text" (click)="saveAboutUs(aboutUsData.id)">
					Save
				</button>
			</div>
		</div>

		<div class="form-field">
			<div class="field-title-container">
				<p>Privacy Policy:</p>
				<span>Change the privacy policy for the website</span>
			</div>

			<div class="form-field-container">
				<ckeditor [editor]="editor" [(ngModel)]="privacyPolicyData.text"></ckeditor>
				<button pButton pTooltip="Save privacy policy" (click)="savePrivacyPolicy(privacyPolicyData.id)">
					Save
				</button>
			</div>
		</div>

		<div class="form-field">
			<div class="field-title-container">
				<p>Terms of service:</p>
				<span>Change the terms of service for the website</span>
			</div>
			<div class="form-field-container">
				<ckeditor [editor]="editor" [(ngModel)]="termsOfServiceData.text"></ckeditor>
				<button pButton pTooltip="Save terms of service" (click)="saveTermsOfService(termsOfServiceData.id)">
					Save
				</button>
			</div>
		</div>
	</div>
</div>