import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Api } from 'src/app/const/api';
import { DialogAction } from 'src/app/enums/dialog-action';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-add-category',
	templateUrl: './add-category.component.html',
	styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent implements OnInit {
	dialogAction: DialogAction;
	categoryFrom: FormGroup;
	categoryData: any;

	categoryPosition = [
		{
			title: 'Left',
			value: 'L',
		},
		{
			title: 'Right',
			value: 'R',
		},
	];

	constructor(
		private utility: UtilityService,
		private http: HttpService,
		private ref: DynamicDialogRef,
		private dialogConfig: DynamicDialogConfig,
	) { }

	ngOnInit(): void {
		this.categoryFrom = new FormGroup({
			category_name: new FormControl('', [Validators.required]),
			category_code: new FormControl('', [Validators.required]),
			category_description: new FormControl('', [Validators.required]),
			position: new FormControl('L', [Validators.required]),
		});
		const { type, data } = this.dialogConfig.data;
		this.dialogAction = type;

		if (this.dialogAction == DialogAction.EDIT) {
			this.categoryData = data;
			this.categoryFrom.patchValue({
				category_name: this.categoryData.category_name,
				category_code: this.categoryData.category_code,
				category_description: this.categoryData.category_description,
				position: this.categoryData.position,
			});
		}
	}

	get category_name() {
		return this.categoryFrom.get('category_name');
	}

	get category_code() {
		return this.categoryFrom.get('category_code');
	}

	get category_description() {
		return this.categoryFrom.get('category_description');
	}

	get position() {
		return this.categoryFrom.get('position');
	}

	submitCategoryData() {
		if (this.dialogAction == DialogAction.ADD) {
			this.addCategory();
		} else if (this.dialogAction == DialogAction.EDIT) {
			this.editCategory();
		}
	}

	addCategory() {
		if (this.categoryFrom.valid) {
			this.utility.showLoading();
			let params = {
				category_name: this.categoryFrom.value.category_name,
				category_code: this.categoryFrom.value.category_code,
				category_description: this.categoryFrom.value.category_description,
				position: this.categoryFrom.value.position,
			};
			this.http.post(Api.ADD_CATEGORIES, params).subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('Category Added.');
					this.categoryFrom.reset();
				},
				error: (err: any) => {
					console.log(err);
					this.utility.showSuccessToast('Unable to add category.');
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showSuccessToast('Please check your details.');
		}
	}

	editCategory() {
		if (this.categoryFrom.valid) {
			this.utility.showLoading();
			let params = {
				category_name: this.categoryFrom.value.category_name,
				category_code: this.categoryFrom.value.category_code,
				category_description: this.categoryFrom.value.category_description,
				position: this.categoryFrom.value.position,
			};
			this.http.patch(Api.UPDATE_CATEGORY(this.categoryData.id), params).subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('Category Updated.');
					this.closeDialog();
				},
				error: (err: any) => {
					console.log(err);
					this.utility.showSuccessToast('Unable to update category.');
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showSuccessToast('Please check your details.');
		}
	}

	closeDialog() {
		this.ref.close();
	}
}
