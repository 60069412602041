import { Routes } from '@angular/router';
import { ProductsComponent } from './products/products.component';
import { AddProductComponent } from './add-product/add-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';

export const ProductRoutingModule: Routes = [
	{ path: 'products', component: ProductsComponent },
	{ path: 'add-product', component: AddProductComponent },
	{ path: 'edit-product/:id', component: EditProductComponent },
];
