import { Routes } from '@angular/router';
import { ConsumerDetailComponent } from './consumer-detail/consumer-detail.component';
import { ConsumersComponent } from './consumers/consumers.component';
import { TestersComponent } from './testers/testers.component';
import { TesterDetailsComponent } from './tester-details/tester-details.component';

export const ConsumerRoutingModule: Routes = [
	{ path: 'consumers', component: ConsumersComponent },
	{ path: 'consumers/:userId', component: ConsumerDetailComponent },
	{ path: 'testers', component: TestersComponent },
	{ path: 'tester/:userId', component: TesterDetailsComponent },
];
