<p-table
	#table
	dataKey="id"
	[columns]="columns"
	[value]="data"
	[tableStyle]="{ 'min-width': '60rem' }"
	[paginator]="paginator"
	[rows]="rows"
	[rowsPerPageOptions]="[15, 20, 25, 50]"
	[selectionMode]="'single'"
	rowGroupMode="subheader"
	styleClass="p-datatable-striped p-datatable-gridlines text-md"
	sortMode="single"
	[sortOrder]="sortOrder"
	[sortField]="sortField">
	<ng-template pTemplate="header" let-columns>
		<tr>
			<th
				*ngFor="let col of columns"
				[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
				[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
				[pSortableColumn]="col.field"
				[pSortableColumnDisabled]="col.disableSort"
				[class]="col.class">
				<div class="flex items-center">
					{{ col.header }}
					<p-sortIcon [field]="col.field" *ngIf="!col.disableSort"></p-sortIcon>
				</div>
			</th>
		</tr>

		<tr *ngIf="showFilter">
			<th
				*ngFor="let col of columns"
				[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
				[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
				[class]="col.class">
				<p-columnFilter
					type="text"
					[field]="col.field"
					*ngIf="
						col.field != 'Action' && col.field != 'Photo' && col.field != 'Image'
					"></p-columnFilter>
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
		<tr class="text-[12px] md:text-[14px]" (click)="onRowClick(rowData, rowIndex)">
			<ng-container *ngFor="let col of columns">
				<td
					class="flex gap-1 items-center"
					*ngIf="showActions && col.fieldType == fieldTypes.Action"
					[class]="col.class">
					<div class="flex gap-1">
						<button
							pButton
							pRipple
							type="button"
							pTooltip="Edit"
							icon="pi pi-pencil"
							(click)="$event.stopPropagation(); onEdit(rowData, rowIndex)"
							class="p-button-rounded p-button-text"
							*ngIf="showEditAction"></button>

						<button
							pButton
							pRipple
							type="button"
							pTooltip="Delete"
							icon="pi pi-trash"
							(click)="$event.stopPropagation(); onDelete(rowData, rowIndex)"
							class="p-button-rounded p-button-text"
							*ngIf="showDeleteAction || rowData.showDeleteAction"
							[disabled]="rowData.IsReadOnly"></button>

						<button
							pButton
							pRipple
							type="button"
							[pTooltip]="extraActionToolTip"
							[icon]="extraActionIcon"
							(click)="$event.stopPropagation(); onExtraAction(rowData, rowIndex)"
							class="p-button-rounded p-button-text"
							*ngIf="showExtraAction"></button>
					</div>
				</td>
				<td
					[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
					[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
					*ngIf="col.fieldType == fieldTypes.Text || col.fieldType == fieldTypes.Email"
					[class]="'cursor-pointer ' + col.class"
					[pTooltip]="col.showToolTip ? rowData[col.field] : ''"
					[autoHide]="false"
					tooltipPosition="bottom">
					{{ rowData[col.field] ? rowData[col.field] : '-' }}
				</td>
				<td
					[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
					*ngIf="col.fieldType == fieldTypes.Category"
					[class]="'cursor-pointer text-left '"
					[pTooltip]="col.showToolTip ? rowData[col.field] : ''"
					[autoHide]="false"
					tooltipPosition="bottom">
					<span class="flex gap-1 items-center">
						<div
							class="h-[14px] w-[14px] bg-[#c4c4c4]"
							[style.background]="rowData[col.colorField]"></div>
						<span [class]="col.class">
							{{ rowData[col.field] }}
						</span>
					</span>
				</td>
				<td
					[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
					[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
					*ngIf="col.fieldType == fieldTypes.Telephone"
					[class]="'cursor-pointer ' + col.class"
					[pTooltip]="col.showToolTip ? rowData[col.field] : ''"
					[autoHide]="false"
					tooltipPosition="bottom">
					{{ rowData[col.field] }}
				</td>
				<td
					[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
					[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
					*ngIf="col.fieldType == fieldTypes.Date"
					[class]="'cursor-pointer'">
					{{
						rowData[col.field] | date : (col.dateFormat ? col.dateFormat : 'MM/dd/yyyy')
					}}
				</td>
				<td
					[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
					[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
					*ngIf="col.fieldType == fieldTypes.Number"
					[class]="'cursor-pointer'">
					{{ col?.prefix
					}}{{ (rowData[col.field] ? rowData[col.field] : 0) | number : '1.2-2'
					}}{{ col?.suffix }}
				</td>
				<td
					[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
					[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
					*ngIf="col.fieldType == fieldTypes.Boolean"
					[class]="'cursor-pointer ' + col.class">
					{{ rowData[col.field] == col.checkValue ? col.trueValue : col.falseValue }}
				</td>
				<td
					class="cursor-pointer text-blue-500"
					[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
					[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
					*ngIf="col.fieldType == fieldTypes.LinkButton"
					[class]="'cursor-pointer ' + col.class">
					<span (click)="col.onClick()">
						{{ col.header }}
					</span>
				</td>
				<td
					class="cursor-pointer"
					[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
					[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
					*ngIf="col.fieldType == fieldTypes.Image"
					[class]="'cursor-pointer'">
					<img
						[src]="col.imageBaseUrl + rowData[col.field]"
						[alt]=""
						class="h-14 w-14 max-h-14 max-w-14 rounded-md my-2 object-contain" />
				</td>
				<td
					[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
					[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
					*ngIf="col.fieldType == fieldTypes.DaysAgo"
					[class]="'cursor-pointer ' + col.class">
					{{ rowData[col.field] ? (rowData[col.field] | daysAgo) : '-' }}
				</td>
				<td
					class="cursor-pointer"
					[ngStyle]="{ width: col.width, 'max-width': col.width, 'min-width': col.width }"
					[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
					*ngIf="col.fieldType == fieldTypes.ImageGroup"
					[class]="'cursor-pointer ' + col.class">
					<div class="relative flex gap-1">
						<ng-container *ngFor="let img of rowData[col.field]">
							<img
								[src]="img[col.imageKey]"
								[alt]="img[col.imageAltKey]"
								class="h-5 w-5 flex-shrink-0 rounded-full"
								onError="this.src = '/assets/images/placeholder-image.webp'"
								[pTooltip]="img[col.imageAltKey] + ' liked this'"
								*ngIf="img[col.imageKey]" />

							<img
								[src]="
									'https://ui-avatars.com/api/?background=random&name=' +
									img[col.imageAltKey]
								"
								[alt]="img[col.imageAltKey]"
								class="h-5 w-5 flex-shrink-0 rounded-full"
								*ngIf="!img[col.imageKey]" />
						</ng-container>
					</div>

					<!-- <p-avatarGroup>
						<p-avatar size="normal" shape="circle" *ngFor="let img of rowData[col.field]"
							styleClass="h-5 w-5">
							<img [src]="img[col.imageKey]" [alt]="img[col.imageAltKey]"
								onError="this.src = '/assets/images/placeholder-image.webp'"
								[pTooltip]="img[col.imageAltKey] + ' liked this'" *ngIf="img[col.imageKey]">

							<img [src]="'https://ui-avatars.com/api/?background=random&name=' + img[col.imageAltKey]"
								[alt]="img[col.imageAltKey]" *ngIf="!img[col.imageKey]" />
						</p-avatar>
					</p-avatarGroup> -->
				</td>
			</ng-container>
		</tr>
	</ng-template>
</p-table>
