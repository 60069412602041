<div class="page">
	<div class="add-product-container">
		<form [formGroup]="emailFrom" (ngSubmit)="sendEmail()">
			<app-field-with-label title="Email Subject" [required]="true">
				<input type="text" pInputText formControlName="emailSubject" />
				<span *ngIf="emailSubject.invalid && (emailSubject.dirty || emailSubject?.touched)">
					<span *ngIf="emailSubject?.errors?.['required']">
						* Email Subject is required
					</span>
				</span>
			</app-field-with-label>

			<app-field-with-label title="Email Content" [required]="true">
				<ckeditor [editor]="editor" formControlName="emailContent"></ckeditor>
				<span *ngIf="emailContent.invalid && (emailContent.dirty || emailContent?.touched)">
					<span *ngIf="emailContent?.errors?.['required']">
						* Email content is required
					</span>
				</span>
			</app-field-with-label>

			<div class="w-full flex justify-end mt-4">
				<button DialogService type="submit" class="w-auto px-4">
					Send Email
				</button>
			</div>
		</form>
	</div>
</div>