import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
	selector: 'app-loader',
	standalone: true,
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
	imports: [ProgressSpinnerModule],
})
export class LoaderComponent { }
