import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from 'src/app/components/components.module';
import { AddProductComponent } from './add-product/add-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { ProductRoutingModule } from './product-routing.module';
import { ProductsComponent } from './products/products.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { FieldWithLabelComponent } from 'src/app/components/field-with-label/field-with-label.component';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ManageProductComponent } from './manage-product/manage-product.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
	declarations: [
		ProductsComponent,
		AddProductComponent,
		EditProductComponent,
		ManageProductComponent,
	],
	imports: [
		CommonModule,
		RouterModule.forChild(ProductRoutingModule),
		ComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMaskModule.forRoot(),
		CKEditorModule,
		PageHeaderComponent,
		FieldWithLabelComponent,
		DropdownModule,
		TableModule,
		InputSwitchModule,
		ButtonModule,
		RadioButtonModule,
		TooltipModule,
		InputTextModule,
		InputTextareaModule,
		InputNumberModule,
		PipesModule,
	],
})
export class ProductModule {}
