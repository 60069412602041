<div class="page">
	<div class="mt-4">
		<div class="flex flex-col gap-1">
			<div class="field-title-container">
				<p>Select moderator for this Category</p>
			</div>
			<div class="w-full">
				<p-autoComplete [(ngModel)]="selectedTopicUsers" [suggestions]="topicUsers" [multiple]="true"
					[field]="'email'" [minLength]="1" appendTo="body" (completeMethod)="searchUser($event)"
					styleClass="w-full">
				</p-autoComplete>
				<!-- <mat-form-field appearance="outline" floatLabel="never">
					<mat-chip-list #chipList>
						<mat-chip *ngFor="let u of selectedTopicUsers" (removed)="removeSelectedModerator(u.id)">
							{{u.email}}
							<button matChipRemove>
								<mat-icon>cancel</mat-icon>
							</button>
						</mat-chip>
					</mat-chip-list>
					<input placeholder="Type name to add Moderator..." #newModeratorInput [formControl]="moderatorCtrl"
						[matChipInputFor]="chipList" [matAutocomplete]="auto"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes" class="w-full mt-4" />
					<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onUserSelect($event)">
						<mat-option *ngFor="let u of filteredTopicUsers | async" [value]="u.id">
							{{u.email}}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field> -->
			</div>
		</div>

		<div class="flex gap-2 justify-end mt-4">
			<button pButton type="button" class="w-auto" (click)="closeDialog()">Cancel</button>
			<button pButton type="button" class="w-auto" (click)="saveModerator()">
				Save Moderators
			</button>
		</div>
	</div>
</div>