import { Routes } from '@angular/router';
import { AddProductComponent } from './add-product/add-product.component';
import { ProductsComponent } from './products/products.component';
// import { EditProductComponent } from './edit-product/edit-product.component';
import { ManageProductComponent } from './manage-product/manage-product.component';

export const ProductRoutingModule: Routes = [
	{ path: 'products', component: ProductsComponent },
	// { path: 'add-product', component: AddProductComponent },
	// { path: 'edit-product/:id', component: EditProductComponent },
	{ path: 'add-product', component: ManageProductComponent },
	{ path: 'edit-product/:id', component: ManageProductComponent },
];
