<div class="page">
	<app-page-header header="Sales">
		<button pButton (click)="exportOrders()">
			<span class="material-symbols-rounded"> download </span>
			Export As CSV
		</button>
	</app-page-header>

	<div class="category-container">
		<div class="p-4 rounded shadow-md border border-[#c4c4c4] mt-4">
			<p class="font-semibold text-lg mb-4">Filters</p>
			<div class="flex gap-2 items-center">
				<div class="flex-grow flex gap-2">
					<app-field-with-label header="Enter a date range">
						<p-calendar
							[(ngModel)]="filterParams.dates"
							selectionMode="range"
							[readonlyInput]="true"
							placeholder="Select a Date"></p-calendar>
					</app-field-with-label>

					<app-field-with-label header="Category">
						<p-dropdown
							[options]="categories"
							[(ngModel)]="filterParams.category_id"
							optionLabel="category_name"
							optionValue="id"
							placeholder="Select a Category"></p-dropdown>
					</app-field-with-label>

					<app-field-with-label header="Brand">
						<p-dropdown
							[options]="brands"
							[(ngModel)]="filterParams.brand_id"
							optionLabel="brand_name"
							optionValue="id"
							placeholder="Select a Brand"></p-dropdown>
					</app-field-with-label>

					<app-field-with-label header="Supplier">
						<p-dropdown
							[options]="suppliers"
							[(ngModel)]="filterParams.supplier_id"
							optionLabel="company_name"
							optionValue="id"
							placeholder="Select a Supplier">
							<!-- <ng-template pTemplate="selectedItem">
								<div class="flex align-items-center gap-2" *ngIf="filterParams.supplier_id">
									<div>{{ selectedCountry.name }}</div>
								</div>
							</ng-template> -->
							<ng-template let-supplier pTemplate="item">
								<div class="">
									{{ supplier.company_name }} - {{ supplier.supplier_name }}
								</div>
							</ng-template>
						</p-dropdown>
					</app-field-with-label>
				</div>

				<div class="flex flex-col gap-2 justify-between">
					<button type="button" pButton (click)="filterSales()">Apply Filter</button>

					<button type="button" pButton (click)="clearFilters()">Clear Filters</button>
				</div>
			</div>
		</div>

		<app-simple-table
			[columns]="columns"
			[data]="sales"
			[paginator]="true"
			[showFilter]="false"
			[showActions]="false"
			[onRowClick]="salesDetails"
			[sortField]="'created_at'">
		</app-simple-table>
	</div>
</div>
