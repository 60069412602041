<div class="page">
	<form [formGroup]="from" (ngSubmit)="submitData()">
		<div class="flex flex-col gap-4">
			<app-field-with-label title="Invite Code" [required]="true">
				<input type="text" pInputText formControlName="invite_code" />
				<span *ngIf="inviteCode.invalid && (inviteCode.dirty || inviteCode?.touched)">
					<span *ngIf="inviteCode?.errors?.['required']"> Invite Code is required </span>
				</span>
			</app-field-with-label>

			<app-field-with-label title="Expiry Date" [required]="true">
				<p-calendar formControlName="expired_on" appendTo="body"></p-calendar>
				<span *ngIf="expiredOn.invalid && (expiredOn.dirty || expiredOn?.touched)">
					<span *ngIf="expiredOn?.errors?.['required']"> Expiry Date is required </span>
				</span>
			</app-field-with-label>

			<div class="flex flex-col gap-4">
				<button pButton type="button" (click)="generateRandomInviteCode()">
					Generate Random Code
				</button>
				<button pButton type="submit">Submit</button>
			</div>
		</div>
	</form>
</div>