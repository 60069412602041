import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { ExportService } from 'src/app/services/export/export.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { ApiMessages } from 'src/app/const/messages';
import { EmailDialogComponent } from 'src/app/components/email-dialog/email-dialog.component';
import { IndividualEmailDialogComponent } from 'src/app/components/individual-email-dialog/individual-email-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { Router } from '@angular/router';
import { NgxCSVParserError, NgxCsvParser } from 'ngx-csv-parser';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog/confirmation-dialog.service';
import { ConfirmationService } from 'primeng/api';

@Component({
	selector: 'app-testers',
	templateUrl: './testers.component.html',
	styleUrls: ['./testers.component.scss'],
	providers: [ConfirmationService],
})
export class TestersComponent implements OnInit {
	columns = [
		{
			field: 'name',
			header: 'Consumer Name',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'email',
			header: 'Email',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'mobile',
			header: 'Phone',
			disableSort: false,
			fieldType: FieldsType.Telephone,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'tester_status',
			header: 'Status',
			disableSort: false,
			fieldType: FieldsType.Custom,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'action',
			header: 'Action',
			disableSort: false,
			fieldType: FieldsType.Action,
		},
	];
	testers: any[] = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private exportService: ExportService,
		private dialogService: DialogService,
		private router: Router,
		private ngxCsvParser: NgxCsvParser,
		private confirmationDialogService: ConfirmationDialogService,
	) {
		dayjs.extend(timezone);
		dayjs.extend(utc);
	}

	get fieldTypes() {
		return FieldsType;
	}

	ngOnInit(): void {
		this.getTesters();
	}

	getTesters() {
		this.http.get(Api.GET_TESTERS).subscribe({
			next: (res: any) => {
				this.testers = res;
			},
			error: (err: any) => {
				console.log(err);
			},
		});
	}

	exportCSV() {
		const csvColumns = ['#', 'First Name', 'Last Name', 'Email', 'Phone', 'Status'];
		let csvData = [];
		let dataToExport = [];
		dataToExport = this.testers;
		let index = 0;
		for (const data of dataToExport) {
			index++;
			let status = '';
			if (data.tester_status == 'PENDING') {
				status = 'Pending';
			} else if (data.tester_status == 'REJECTED') {
				status = 'Rejected';
			} else if (data.tester_status == 'TESTER') {
				status = 'Approved';
			}
			csvData.push({
				'#': index,
				'First Name': `${data.first_name}`,
				'Last Name': `${data.last_name}`,
				Email: data.email,
				Phone: data.mobile,
				Status: status,
			});
		}
		this.exportService.exportToCsv(
			csvData,
			`testers-${dayjs().format('DD-MM-YYYY-HH-mm')}`,
			csvColumns,
		);
	}

	sendEmail() {
		const dialogRef = this.dialogService.open(EmailDialogComponent, {
			header: 'Send Email',
			width: '50%',
		});

		dialogRef.onClose.subscribe((params: any) => {
			this.http.post(Api.SEND_EMAIL_TO_TESTERS, params).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(res.data);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.USERS_EMAIL_SENT_FAIL);
				},
				complete: () => { },
			});
		});
	}

	sendIndividualEmail() {
		const emails = this.testers.map((data: any) => data.email);
		const dialogRef = this.dialogService.open(IndividualEmailDialogComponent, {
			header: 'Send Email',
			width: '50%',
			data: {
				emails: emails,
			},
		});

		dialogRef.onClose.subscribe((params: any) => {
			this.http.post(Api.SEND_INDIVIDUAL_EMAIL, params).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(res.data);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.USERS_EMAIL_SENT_FAIL);
				},
				complete: () => { },
			});
		});
	}

	viewTester = (tester: any, index: any) => {
		this.router.navigate(['/tester', tester.id]);
	};

	onCsvFileSelect(event: any) {
		const file = event.target.files[0];
		this.confirmationDialogService.showConfirmationDialog(
			'Import Selected CSV File?',
			`Are you sure you want to import the "<b>${file.name}</b>" file?  <br/>
			<b>It can not be reverted</b>`,
			() => {
				this._processImportCsv(file);
			},
			() => { },
			() => { },
		);
	}

	_processImportCsv(file: File) {
		this.utilityService.showLoading();
		this.ngxCsvParser
			.parse(file, {
				header: false,
				delimiter: ',',
			})
			.pipe()
			.subscribe({
				next: (result: Array<any>) => {
					result.splice(0, 1);
					this.http.post(Api.IMPORT_TESTERS, { testers: result }).subscribe({
						next: (res: any) => {
							this.utilityService.hideLoading();
							this.utilityService.showSuccessToast('Testers Imported Successfully.');
						},
						error: (err: any) => {
							console.log(err);
							this.utilityService.hideLoading();
							this.utilityService.showErrorToast('Unable to Import Testers.');
						},
						complete: () => {
							this.utilityService.hideLoading();
						},
					});
				},
				error: (error: NgxCSVParserError) => {
					console.log('Error', error);
				},
			});
	}
}
