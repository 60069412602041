import { Component } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { FieldWithLabelComponent } from '../field-with-label/field-with-label.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: 'app-email-dialog',
	templateUrl: './email-dialog.component.html',
	styleUrls: ['./email-dialog.component.scss'],
	imports: [
		CommonModule,
		PageHeaderComponent,
		ReactiveFormsModule,
		FieldWithLabelComponent,
		CKEditorModule,
		ButtonModule,
		InputTextModule,
	],
})
export class EmailDialogComponent {
	emailFrom: FormGroup;
	public editor: any = ClassicEditor;

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private ref: DynamicDialogRef,
	) {
		this.emailFrom = new FormGroup({
			emailSubject: new FormControl('', [Validators.required]),
			emailContent: new FormControl('', [Validators.required]),
		});
	}

	get emailSubject() {
		return this.emailFrom.get('emailSubject');
	}
	get emailContent() {
		return this.emailFrom.get('emailContent');
	}

	sendEmail() {
		if (this.emailFrom.valid) {
			this.utilityService.showLoading();
			let { emailSubject, emailContent } = this.emailFrom.value;
			const params = {
				email_subject: emailSubject,
				email_body: emailContent,
			};

			this.ref.close(params);

			// this.http.post(Api.SEND_EMAIL_TO_SUBSCRIBERS, params).subscribe({
			// 	next: (res: any) => {
			// 		this.emailFrom.reset();
			// 		this.emailFrom.clearValidators();
			// 		this.utilityService.hideLoading();
			// 		this.utilityService.showSnackBar(res.data);
			// 	},
			// 	error: (err: any) => {
			// 		this.utilityService.hideLoading();
			// 		this.utilityService.showSnackBar(ApiMessages.SUBSCRIBER_EMAIL_SENT_FAIL);
			// 	},
			// 	complete: () => {},
			// });
		} else {
			this.utilityService.showSuccessToast('Required fields are missing.');
			this.emailFrom.markAllAsTouched();
		}
	}
}
