<div class="page">
	<app-page-header header="Send Email"></app-page-header>
	<div class="add-product-container">
		<form [formGroup]="emailFrom" (ngSubmit)="sendEmail()">
			<div class="flex flex-col gap-4">
				<app-field-with-label title="Select or Enter Email" [required]="true">
					<p-autoComplete formControlName="emails" [suggestions]="allEmails"
									[multiple]="true"
									[minLength]="1" appendTo="body" (completeMethod)="searchUser($event)"
									styleClass="w-full"></p-autoComplete>
				</app-field-with-label>

				<app-field-with-label title="Email Subject" [required]="true">
					<input type="email" pInputText formControlName="emailSubject" [required]="true" />
					<span *ngIf="emailSubject.invalid && (emailSubject.dirty || emailSubject?.touched)">
						<span *ngIf="emailSubject?.errors?.['required']">
							* Email Subject is required
						</span>
					</span>
				</app-field-with-label>

				<app-field-with-label title="Email Content" [required]="true">
					<ckeditor [editor]="editor" formControlName="emailContent"></ckeditor>
					<span *ngIf="emailContent.invalid && (emailContent.dirty || emailContent?.touched)">
						<span *ngIf="emailContent?.errors?.['required']">
							* Email content is required
						</span>
					</span>
				</app-field-with-label>

				<div class="flex justify-end">
					<button pButton type="submit" class="w-auto px-4">
						Send Email
					</button>
				</div>
			</div>
		</form>
	</div>
</div>