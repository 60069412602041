import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-tester-details',
	templateUrl: './tester-details.component.html',
	styleUrls: ['./tester-details.component.scss'],
})
export class TesterDetailsComponent implements OnInit {
	userId: string = '';
	userData: any;
	testerStatus: any = '';
	testerStatusList = [
		// {
		// 	title: 'Requested',
		// 	value: 'REQUESTED',
		// },
		{
			title: 'Reject',
			value: 'REJECTED',
		},
		{
			title: 'Pending',
			value: 'PENDING',
		},
		{
			title: 'Approve',
			value: 'TESTER',
		},
	];
	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private route: ActivatedRoute,
	) {
		this.route.params.subscribe((params) => {
			this.userId = params['userId'];
			this.getTesterDetails();
		});
	}

	ngOnInit(): void { }

	getTesterDetails() {
		this.http.get(Api.GET_CONSUMERS_DETAILS(this.userId)).subscribe({
			next: (res: any) => {
				this.userData = res;
				this.testerStatus = res.tester_status;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	saveTesterStatus() {
		const params = {
			userId: this.userId,
			status: this.testerStatus,
		};
		this.http.post(Api.UPDATE_TESTER_STATUS, params).subscribe({
			next: (res: any) => {
				this.utilityService.showSuccessToast('Tester status updated.');
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}
}
