import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-website-settings',
	templateUrl: './website-settings.component.html',
	styleUrls: ['./website-settings.component.scss'],
})
export class WebsiteSettingsComponent implements OnInit {
	settings: any = {
		min_order_value: 0,
		product_tax_percent: 0,
	};

	constructor(private http: HttpService, private utility: UtilityService) { }

	ngOnInit(): void {
		this.getWebsiteSettings();
	}

	getWebsiteSettings() {
		this.http.get(Api.GET_WEB_CONFIGS).subscribe({
			next: (res: any) => {
				const settingRes = res;
				const settings = {};
				for (const setting of settingRes) {
					settings[setting.setting_name] = setting.setting_value;
				}
				this.settings = settings;
			},
			error: (err: any) => {
				console.log(err);
			},
		});
	}

	saveSettings() {
		this.http.patch(Api.SAVE_WEB_CONFIGS, this.settings).subscribe({
			next: (res: any) => {
				this.utility.showSuccessToast('Settings Changed.');
			},
			error: (err: any) => {
				this.utility.showErrorToast('Unable to change Settings.');
			},
		});
	}
}
