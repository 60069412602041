<div class="page">
	<app-page-header header="Tester | {{ userData?.first_name }} {{ userData?.last_name }}"></app-page-header>

	<div class="" *ngIf="userData">
		<div class="border-b border-gray-300 py-2">
			<div class="flex">
				<div class="w-full border-r border-gray-300">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold pt-2 pb-1">Personal Details</h2>
					</div>
					<div class="detail-fields">
						<p>Name:</p>
						<p>{{ userData.first_name }} {{ userData.last_name }}</p>
					</div>

					<div class="detail-fields">
						<p>Email:</p>
						<p class="underline">
							<a href="mailto:{{ userData.email }}">
								{{ userData.email }}
							</a>
						</p>
					</div>

					<div class="detail-fields">
						<p>Supplier Phone:</p>
						<p class="underline">
							<a href="tel:{{ userData.phone_country }}{{ userData.mobile }}">{{
								userData.mobile
								}}</a>
						</p>
					</div>

					<div class="detail-fields">
						<p>Registered On:</p>
						<p>{{ userData.created_at | date : 'medium' }}</p>
					</div>
				</div>

				<div class="w-full pl-4">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold pt-2 pb-1">Orders Summary</h2>
					</div>
					<div class="detail-fields">
						<p>Number of Orders</p>
						<p>{{ userData.total_orders }}</p>
					</div>

					<div class="detail-fields">
						<p>Order Total:</p>
						<p>${{ userData.order_total | number : '1.2-2' }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="border-b border-gray-300 py-2">
			<div class="flex">
				<div class="w-full border-r border-gray-300 pr-12">
					<div class="form-field border rounded-sm p-2">
						<div class="field-title-container">
							<h2 class="text-xl font-semibold pt-2 pb-1">Status</h2>
							<span class="mt-0">Accept / Reject tester request.</span>
						</div>
						<div class="form-field-container flex items-center">
							<p-dropdown [options]="testerStatusList" [(ngModel)]="testerStatus" optionLabel="title"
								optionValue="value" placeholder="Select Tester Status"></p-dropdown>

							<!-- <mat-select [(ngModel)]="testerStatus">
								<mat-option value="">Select Tester Status</mat-option>
								<mat-option *ngFor="let s of testerStatusList" [value]="s.value">
									{{ s.title }}
								</mat-option>
							</mat-select> -->
						</div>
					</div>
					<div class="flex mt-2 justify-end">
						<button pButton type="button" pTooltip="Save Tester Status" (click)="saveTesterStatus()">
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>