<div class="page">
	<app-page-header header="Consumers">
		<button pButton pTooltip="Export data as CSV" tooltipPosition="top" (click)="exportCSV()">
			<span class="material-symbols-rounded"> download </span>
			Export As CSV
		</button>
		<button
			pButton
			pTooltip="Send email to all Consumers"
			tooltipPosition="top"
			(click)="sendEmail()">
			<span class="material-symbols-rounded"> send </span>
			Email to Consumers
		</button>
		<button
			pButton
			pTooltip="Send email to individual Consumers"
			tooltipPosition="top"
			(click)="sendIndividualEmail()">
			<span class="material-symbols-rounded"> contact_mail </span>
			Send Email
		</button>
	</app-page-header>

	<div class="consumer-container">
		<!-- <app-simple-table [columns]="columns" [data]="consumer" [paginator]="true" [showFilter]="false"
			[showActions]="false" [onRowClick]="viewConsumer">
		</app-simple-table> -->

		<p-table
			#table
			dataKey="id"
			[columns]="columns"
			[value]="consumer"
			[tableStyle]="{ 'min-width': '60rem' }"
			[paginator]="true"
			[rows]="20"
			[rowsPerPageOptions]="[15, 20, 25, 50]"
			[selectionMode]="'single'"
			rowGroupMode="subheader"
			styleClass="p-datatable-striped p-datatable-gridlines text-md"
			sortMode="multiple">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th
						*ngFor="let col of columns"
						[ngStyle]="{
							width: col.width,
							'max-width': col.width,
							'min-width': col.width
						}"
						[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
						[pSortableColumn]="col.field"
						[pSortableColumnDisabled]="col.disableSort"
						[class]="col.class">
						<div class="flex items-center">
							{{ col.header }}
							<p-sortIcon [field]="col.field" *ngIf="!col.disableSort"></p-sortIcon>
						</div>
					</th>
				</tr>

				<tr *ngIf="true">
					<th
						*ngFor="let col of columns"
						[ngStyle]="{
							width: col.width,
							'max-width': col.width,
							'min-width': col.width
						}"
						[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
						[class]="col.class">
						<p-columnFilter
							type="text"
							[field]="col.field"
							*ngIf="
								col.field != 'Action' &&
								col.field != 'Photo' &&
								col.field != 'Image' &&
								!col.hideFilter
							"></p-columnFilter>
					</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
				<tr class="text-[12px] md:text-[14px]" (click)="viewConsumer(rowData)">
					<ng-container *ngFor="let col of columns">
						<!-- <td class="flex gap-1 items-center" *ngIf="col.fieldType == fieldTypes.Action" [class]="col.class">
							<div class="flex gap-1">
								<button pButton pRipple type="button" pTooltip="Edit" icon="pi pi-pencil"
									(click)="$event.stopPropagation(); editProduct(rowData, rowIndex)"
									class="p-button-rounded p-button-text"></button>
		
								<button pButton pRipple type="button" pTooltip="Delete" icon="pi pi-trash"
									(click)="$event.stopPropagation(); deleteProduct(rowData, rowIndex)"
									class="p-button-rounded p-button-text"></button>
		
								<button pButton pRipple type="button" [pTooltip]="extraActionToolTip"
											[icon]="extraActionIcon"
											(click)="$event.stopPropagation(); onExtraAction(rowData, rowIndex)"
											class="p-button-rounded p-button-text" *ngIf="showExtraAction"></button>
							</div>
						</td> -->
						<td
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
							*ngIf="
								col.fieldType == fieldTypes.Text ||
								col.fieldType == fieldTypes.Email
							"
							[class]="'cursor-pointer ' + col.class"
							[pTooltip]="col.showToolTip ? rowData[col.field] : ''"
							[autoHide]="false"
							tooltipPosition="bottom">
							{{ rowData[col.field] ? rowData[col.field] : '-' }}
						</td>
						<td
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							*ngIf="col.fieldType == fieldTypes.Category"
							[class]="'cursor-pointer text-left '"
							[pTooltip]="col.showToolTip ? rowData[col.field] : ''"
							[autoHide]="false"
							tooltipPosition="bottom">
							<span class="flex gap-1 items-center">
								<div
									class="h-[14px] w-[14px] bg-[#c4c4c4]"
									[style.background]="rowData[col.colorField]"></div>
								<span [class]="col.class">
									{{ rowData[col.field] }}
								</span>
							</span>
						</td>
						<td
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
							*ngIf="col.fieldType == fieldTypes.Telephone"
							[class]="'cursor-pointer ' + col.class"
							[pTooltip]="col.showToolTip ? rowData[col.field] : ''"
							[autoHide]="false"
							tooltipPosition="bottom">
							{{ rowData[col.field] }}
						</td>
						<td
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
							*ngIf="col.fieldType == fieldTypes.Date"
							[class]="'cursor-pointer'">
							{{
								rowData[col.field]
									| date : (col.dateFormat ? col.dateFormat : 'MM/dd/yyyy')
							}}
						</td>
						<td
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
							*ngIf="col.fieldType == fieldTypes.Number"
							[class]="'cursor-pointer'">
							{{ col?.prefix
							}}{{ (rowData[col.field] ? rowData[col.field] : 0) | number : '1.2-2'
							}}{{ col?.suffix }}
						</td>
						<td
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
							*ngIf="col.fieldType == fieldTypes.Boolean"
							[class]="'cursor-pointer ' + col.class">
							{{
								rowData[col.field] == col.checkValue
									? col.trueValue
									: col.falseValue
							}}
						</td>
						<td
							class="cursor-pointer"
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
							*ngIf="col.fieldType == fieldTypes.Image"
							[class]="'cursor-pointer'">
							<img
								[src]="col.imageBaseUrl + rowData[col.field]"
								[alt]=""
								class="h-14 w-14 max-h-14 max-w-14 rounded-md my-2" />
						</td>
						<td
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
							*ngIf="col.fieldType == fieldTypes.DaysAgo"
							[class]="'cursor-pointer ' + col.class">
							{{ rowData[col.field] ? (rowData[col.field] | daysAgo) : '-' }}
						</td>

						<td
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
							*ngIf="col.fieldType == 'forum_access'"
							[class]="'cursor-pointer ' + col.class">
							<span
								class="text-red-800 font-bold"
								*ngIf="rowData.email_verified == 0">
								Read Only</span
							>
							<span
								class="text-yellow-500"
								*ngIf="rowData.email_verified == 1 && !rowData.invite_code"
								>Upvote/Like</span
							>
							<span
								class="text-blue-900"
								*ngIf="rowData.email_verified == 1 && rowData.invite_code"
								>Full Access</span
							>
						</td>

						<td
							class="cursor-pointer"
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
							*ngIf="col.fieldType == fieldTypes.ImageGroup"
							[class]="'cursor-pointer ' + col.class">
							<div class="relative flex gap-1">
								<ng-container *ngFor="let img of rowData[col.field]">
									<img
										[src]="img[col.imageKey]"
										[alt]="img[col.imageAltKey]"
										class="h-5 w-5 flex-shrink-0 rounded-full"
										onError="this.src = '/assets/images/placeholder-image.webp'"
										[pTooltip]="img[col.imageAltKey] + ' liked this'"
										*ngIf="img[col.imageKey]" />
									<img
										[src]="
											'https://ui-avatars.com/api/?background=random&name=' +
											img[col.imageAltKey]
										"
										[alt]="img[col.imageAltKey]"
										class="h-5 w-5 flex-shrink-0 rounded-full"
										*ngIf="!img[col.imageKey]" />
								</ng-container>
							</div>
						</td>

						<td
							class="cursor-pointer text-blue-500"
							[ngStyle]="{
								width: col.width,
								'max-width': col.width,
								'min-width': col.width
							}"
							[ngClass]="{ 'text-left': !col.isNumeric, 'text-right': col.isNumeric }"
							*ngIf="col.fieldType == fieldTypes.LinkButton"
							[class]="'cursor-pointer ' + col.class">
							<span
								(click)="$event.stopPropagation(); resendVerification(rowData)"
								*ngIf="rowData.email_verified == '0'">
								{{ col.header }}
							</span>
						</td>
					</ng-container>
				</tr>
			</ng-template>
		</p-table>

		<!-- <table mat-table [dataSource]="consumer">
			<ng-container matColumnDef="#">
				<th mat-header-cell *matHeaderCellDef>#</th>
				<td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
			</ng-container>

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef>Consumer Name</th>
				<td mat-cell *matCellDef="let element">
					{{ element.first_name }} {{ element.last_name }}
				</td>
			</ng-container>

			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef>Email</th>
				<td mat-cell *matCellDef="let element">{{ element.email }}</td>
			</ng-container>

			<ng-container matColumnDef="phone">
				<th mat-header-cell *matHeaderCellDef>Phone</th>
				<td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
			</ng-container>

			<ng-container matColumnDef="joined">
				<th mat-header-cell *matHeaderCellDef>Joined On</th>
				<td mat-cell *matCellDef="let element">
					{{ element.created_at | date : 'MMM-dd-YYYY h:mm a' : 'EST' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef>Status</th>
				<td mat-cell *matCellDef="let element">
					{{ element.email_verified == 1 ? 'Verified' : 'Not Verified' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="forum_access">
				<th mat-header-cell *matHeaderCellDef>Forum Access</th>
				<td mat-cell *matCellDef="let element">
					<span class="text-red-800 font-bold" *ngIf="element.email_verified == 0">
						Read Only</span>
					<span class="text-yellow-500"
						*ngIf="element.email_verified == 1 && !element.invite_code">Upvote/Like</span>
					<span class="text-blue-900" *ngIf="element.email_verified == 1 && element.invite_code">Full
						Access</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="moderator">
				<th mat-header-cell *matHeaderCellDef>Moderato</th>
				<td mat-cell *matCellDef="let element">
					{{ element.is_moderator == 1 ? 'Yes' : 'No' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef>Action</th>
				<td mat-cell *matCellDef="let element; let i = index">
					<button mat-icon-button [matMenuTriggerFor]="menu">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<a mat-menu-item [routerLink]="'/consumers/' + element.id">
							<mat-icon>info</mat-icon>
							View
						</a>
					</mat-menu>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="consumerColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: consumerColumns"></tr>
		</table> -->
	</div>
</div>
