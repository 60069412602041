import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierComponent } from './supplier/supplier.component';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SupplierRoutingModule } from './supplier-routing.module';
// import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
// import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
// import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
// import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
// import { MatIconModule } from '@angular/material/icon';
// import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
// import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';
// import { MatSortModule } from '@angular/material/sort';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
	declarations: [SupplierComponent, SupplierDetailsComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(SupplierRoutingModule),
		ComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		CKEditorModule,
		PageHeaderComponent,
		SimpleTableComponent,
		ButtonModule,
		TabViewModule, DropdownModule, TableModule, TooltipModule, PipesModule
	],
})
export class SupplierModule { }
