import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { Api } from 'src/app/const/api';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { TableColumn } from 'src/app/interfaces/table-column';
import { ExportService } from 'src/app/services/export/export.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
	filterParams = {
		customer_name: '',
		dates: '',
	};
	columns: TableColumn[] = [
		{
			field: 'display_id',
			header: 'Order#',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'name',
			header: 'Customer Name',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'created_at',
			header: 'Order Date',
			disableSort: false,
			fieldType: FieldsType.Date,
			dateFormat: 'MMM, dd YYYY @ hh:mm a',
		},
		{
			field: 'total',
			currencyField: 'order_currency',
			header: 'Order Total',
			disableSort: false,
			fieldType: FieldsType.Currency,
			isNumeric: true,
			prefix: '$',
		},
		{
			header: 'Pre Order',
			field: 'is_pre_order',
			disableSort: false,
			fieldType: FieldsType.Boolean,
			checkValue: '1',
			trueValue: 'Yes',
			falseValue: 'No',
		},
		{
			field: 'status',
			header: 'Status',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
	];
	orders: any[] = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private exportService: ExportService,
		private router: Router,
	) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.getOrders();
	}

	getOrders() {
		this.http.get(Api.GET_ALL_ORDERS).subscribe({
			next: (res: any) => {
				this.orders = res;
				// this.orders.sortingDataAccessor = (item, property) => {
				// 	switch (property) {
				// 		case 'created_at':
				// 			console.log(new Date(item.created_at));
				// 			return new Date(item.created_at);
				// 		default:
				// 			return item[property];
				// 	}
				// };
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	exportOrders() {
		const csvColumns = ['#', 'Consumer Name', 'Order Date', 'Order Time', 'Order Total $'];
		let csvData = [];
		let dataToExport = [];
		dataToExport = this.orders;
		let index = 0;
		for (const data of dataToExport) {
			index++;
			csvData.push({
				'#': index,
				'Consumer Name': data.name,
				'Order Date': dayjs(data.created_at).format('MMMM DD, YYYY'),
				'Order Time': dayjs(data.created_at).format('hh:mm a'),
				'Order Total $': `${data.total}`,
			});
		}
		this.exportService.exportToCsv(
			csvData,
			`orders-${dayjs().format('DD-MM-YYYY')}`,
			csvColumns,
		);
	}

	filterOrders() {
		const { customer_name, dates } = this.filterParams;
		const startDateParsed = dates[0] ? dayjs(dates[0]).format('YYYY-MM-DD') : '';
		const endDateParsed = dates[1] ? dayjs(dates[1]).format('YYYY-MM-DD') : '';

		this.http
			.post(Api.GET_FILTER_ORDERS, {
				customer_name: customer_name,
				start_date: startDateParsed,
				end_date: endDateParsed,
			})
			.subscribe({
				next: (res: any) => {
					this.orders = res;
				},
				error: (err) => {
					console.log(err);
				},
				complete: () => {
					this.utilityService.hideLoading();
				},
			});
	}

	clearFilters() {
		this.filterParams = {
			customer_name: '',
			dates: '',
		};
		this.getOrders();
	}

	orderDetails = (orderData: any) => {
		this.router.navigate(['/order-details', orderData.id]);
	};
}
