import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
// import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Api } from 'src/app/const/api';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { TableColumn } from 'src/app/interfaces/table-column';
import { CommonApiCallService } from 'src/app/services/common-api-call/common-api-call.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
	columns: TableColumn[] = [
		{
			field: 'product_main_image',
			header: 'Product Image',
			fieldType: FieldsType.Image,
			imageBaseUrl: environment.imageUrl
		},
		{
			field: 'product_name',
			header: 'Product Name',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
		{
			field: 'supplier_company',
			header: 'Supplier',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
		{
			field: 'product_sku',
			header: 'SKU',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
		{
			field: 'category_name',
			header: 'Category',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
		{
			field: 'status',
			header: 'Status',
			fieldType: FieldsType.Toggle,
			disableSort: false,
		},
		{
			field: 'Action',
			header: 'Action',
			fieldType: FieldsType.Action,
			disableSort: true,
		},
		// 'product_name',
		// 'supplier_name',
		// 'product_sku',
		// 'product_price',
		// 'product_discount_price',
		// 'category_name',
		// 'status',
		// 'action',
	];
	products: any[] = [];
	categories: any = [];
	brands: any = [];
	suppliers: any = [];
	filterParams = {
		supplier_id: '',
		brand_id: '',
		category_id: '',
	};
	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private commonApiCall: CommonApiCallService,
		private router: Router
	) { }

	get fieldTypes() {
		return FieldsType;
	}

	ngOnInit(): void {
		this.getProducts();
		this.getCategories();
		this.getBrands();
		this.getSuppliers();
	}

	getProducts() {
		this.utilityService.showLoading();
		this.http.get(Api.PRODUCTS).subscribe({
			next: (res: any) => {
				this.products = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	getCategories() {
		this.commonApiCall.getCategories().subscribe({
			next: (res) => {
				this.categories = res;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	getBrands() {
		this.http.get(Api.BRANDS).subscribe({
			next: (res) => {
				this.brands = res;
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	getSuppliers() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_SUPPLIERS).subscribe({
			next: (res) => {
				this.suppliers = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	updateProductStatus(event: any, productId: string) {
		let params = { status: event.checked };
		this.http.patch(`${Api.UPDATE_PRODUCT_STATUS}/${productId}`, params).subscribe({
			next: (res: any) => {
				this.utilityService.showSuccessToast('Product status updated');
			},
			error: (err: any) => {
				console.log(err);
			},
		});
	}

	deleteProduct(productData: any, index: number) {
		this.http.delete(Api.DELETE_PRODUCT + productData.id).subscribe({
			next: (res: any) => {
				this.products.splice(index, 1);
				this.utilityService.showSuccessToast('Product deleted.');
			},
			error: (err: any) => {
				this.utilityService.showSuccessToast('Unable to delete product');
			},
		});
	}

	filterProducts() {
		this.utilityService.showLoading();
		this.http.post(Api.GET_FILTER_PRODUCTS, this.filterParams).subscribe({
			next: (res: any) => {
				this.products = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	editProduct = (rowData: any, index: number) => {
		this.router.navigate(['/edit-product', rowData.id])
	}
}
