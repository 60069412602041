import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForumCategoriesComponent } from './forum-categories/forum-categories.component';
import { RouterModule } from '@angular/router';
import { ForumRoutingModule } from './forum-routing-module';
import { AddForumCategoryComponent } from './add-forum-category/add-forum-category.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForumsComponent } from './forums/forums.component';
import { TopicModeratorComponent } from './topic-moderator/topic-moderator.component';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { ButtonModule } from 'primeng/button';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FieldWithLabelComponent } from 'src/app/components/field-with-label/field-with-label.component';
import { InputTextModule } from 'primeng/inputtext';
@NgModule({
	declarations: [
		ForumCategoriesComponent,
		AddForumCategoryComponent,
		ForumsComponent,
		TopicModeratorComponent,
	],
	imports: [
		CommonModule,
		RouterModule.forChild(ForumRoutingModule),
		FormsModule,
		ReactiveFormsModule,
		PageHeaderComponent,
		SimpleTableComponent,
		ButtonModule,
		AutoCompleteModule,
		FieldWithLabelComponent,
		InputTextModule,
	],
})
export class ForumModule { }
