import { Component, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	UntypedFormGroup as FormGroup,
	UntypedFormArray,
	UntypedFormBuilder,
	Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Api } from 'src/app/const/api';
import { ApiMessages } from 'src/app/const/messages';
import {
	domesticated,
	landrace,
	productCbc,
	productCbd,
	productCbda,
	productCBDv,
	productCbg,
	productCbga,
	productCBN,
	productChemovars,
	productClimate,
	productFlowering,
	productGrowType,
	productThc,
	productTHCP,
	productTHCv,
	tissueCultures,
} from 'src/app/const/product-filters';
import { ProductCostUnit } from 'src/app/enums/produnct-cost-unit';
import { CommonApiCallService } from 'src/app/services/common-api-call/common-api-call.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-manage-product',
	templateUrl: './manage-product.component.html',
	styleUrls: ['./manage-product.component.scss'],
})
export class ManageProductComponent implements OnInit {
	productId: string = '';
	categories: any = [];
	brands: any = [];
	suppliers: any = [];
	productData: any = null;
	productForm: FormGroup;
	productVariantFrom: FormGroup;
	selectedMainImage: any = [];
	selectedItemImages: any = [];
	imagePreviews: any = [];
	productMainImagePreview: any;
	existingProductImages: any = [];
	existingProductMainImage: any;
	readonly imageUrl: string = environment.imageUrl;
	isNewImageSelected: boolean = false;
	productGrowReportPreview: any;
	selectedProductGrowReport: any = null;
	existingProductGrowReport: any;

	productGrowType: any;
	productFlowering: any;
	productClimate: any;
	productLandrace: any;
	productDomesticated: any;
	productTissueCulture: any;
	productChemovars: any;
	productThc: any;
	productCbd: any;
	productCbda: any;
	productCbg: any;
	productCbga: any;
	productCbc: any;
	productThcp: any;
	productThcv: any;
	productCbdv: any;
	productCbn: any;
	GRAM_TO_OZ = 0.035274;

	editor: any = ClassicEditor;
	editorConfig = {
		placeholder: '',
		toolbar: {
			items: [],
		},
	};
	taxPercent: number = 0;
	countries: any[] = [
		{ name: 'France', code: 'FR', currency: 'EUR', currencyWithSymbol: 'EUR (€)' },
		{ name: 'Germany', code: 'DE', currency: 'EUR', currencyWithSymbol: 'EUR (€)' },
		{ name: 'Italy', code: 'IT', currency: 'EUR', currencyWithSymbol: 'EUR (€)' },
		{ name: 'Netherlands', code: 'NL', currency: 'EUR', currencyWithSymbol: 'EUR (€)' },
		{ name: 'Spain', code: 'ES', currency: 'EUR', currencyWithSymbol: 'EUR (€)' },
		{ name: 'United Kingdom', code: 'GB', currency: 'GBP', currencyWithSymbol: 'GBP (£)' },
		{ name: 'United States', code: 'US', currency: 'USD', currencyWithSymbol: 'USD ($)' },
	];
	productForDropDown: any[] = [
		{ name: 'Stars and Stripes Seed Co.', value: 'SSSC' },
		{ name: 'Sementis Seeds', value: 'SS' },
	];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private commonApiCall: CommonApiCallService,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
	) {
		this.route.params.subscribe((params) => {
			this.productId = params['id'];
		});
		this.productForm = new FormGroup({
			productName: new FormControl('', [Validators.required]),
			productDescription: new FormControl('', [Validators.required]),
			productLongDescription: new FormControl('', [Validators.required]),
			productVariant: this.formBuilder.array([this.productVariantGroup()]),
			category_id: new FormControl('', [Validators.required]),
			brand_id: new FormControl('', [Validators.required]),
			supplier_id: new FormControl('', [Validators.required]),
			grow_type: new FormControl(''),
			flowering: new FormControl(''),
			climate: new FormControl(''),
			landrace: new FormControl(''),
			domesticated: new FormControl(''),
			tissue_culture: new FormControl(''),
			chemovars: new FormControl(''),
			thc: new FormControl(''),
			cbd: new FormControl(''),
			cbda: new FormControl(''),
			cbg: new FormControl(''),
			cbga: new FormControl(''),
			cbc: new FormControl(''),
			thcp: new FormControl(''),
			thcv: new FormControl(''),
			cbdv: new FormControl(''),
			cbn: new FormControl(''),
			// thca: new FormControl('', [Validators.required]),
			status: new FormControl(true),
			quality_tested: new FormControl(false),
			harvest_date_by_latitude: new FormControl(''),
			allow_pre_order: new FormControl(false),
			grow_report: new FormControl(''),
			currency: new FormControl(''),
			country: new FormControl(''),
			product_for: new FormControl('SSSC'),
		});

		this.productGrowType = productGrowType;
		this.productFlowering = productFlowering;
		this.productClimate = productClimate;
		this.productChemovars = productChemovars;
		this.productLandrace = landrace;
		this.productDomesticated = domesticated;
		this.productTissueCulture = tissueCultures;
		this.productTissueCulture = tissueCultures;
		this.productThc = productThc;
		this.productCbd = productCbd;
		this.productCbda = productCbda;
		this.productCbg = productCbg;
		this.productCbga = productCbga;
		this.productCbc = productCbc;
		this.productThcp = productTHCP;
		this.productThcv = productTHCv;
		this.productCbdv = productCBDv;
		this.productCbn = productCBN;
	}

	get productName() {
		return this.productForm.get('productName');
	}
	get productDescription() {
		return this.productForm.get('productDescription');
	}
	get productLongDescription() {
		return this.productForm.get('productLongDescription');
	}
	get productPrice() {
		return this.productForm.get('productPrice');
	}
	get categoryId() {
		return this.productForm.get('category_id');
	}
	get brandId() {
		return this.productForm.get('brand_id');
	}
	get supplierId() {
		return this.productForm.get('supplier_id');
	}
	get growType() {
		return this.productForm.get('grow_type');
	}
	get flowering() {
		return this.productForm.get('flowering');
	}
	get landrace() {
		return this.productForm.get('landrace');
	}
	get domesticated() {
		return this.productForm.get('domesticated');
	}
	get tissueCulture() {
		return this.productForm.get('tissue_culture');
	}
	get chemovars() {
		return this.productForm.get('chemovars');
	}
	get climate() {
		return this.productForm.get('climate');
	}
	get thc() {
		return this.productForm.get('thc');
	}
	get cbd() {
		return this.productForm.get('cbd');
	}
	get cbda() {
		return this.productForm.get('cbda');
	}
	get cbg() {
		return this.productForm.get('cbg');
	}
	get cbga() {
		return this.productForm.get('cbga');
	}
	get cbc() {
		return this.productForm.get('cbc');
	}
	get thcp() {
		return this.productForm.get('thcp');
	}
	get thcv() {
		return this.productForm.get('thcv');
	}
	get cbdv() {
		return this.productForm.get('cbdv');
	}
	get cbn() {
		return this.productForm.get('cbn');
	}
	get thca() {
		return this.productForm.get('thca');
	}
	get status() {
		return this.productForm.get('status');
	}
	get qualityTested() {
		return this.productForm.get('quality_tested');
	}
	get allowPreOrder() {
		return this.productForm.get('allow_pre_order');
	}
	get country() {
		return this.productForm.get('country');
	}

	get productFor() {
		return this.productForm.get('product_for');
	}

	get ProductCostUnit() {
		return ProductCostUnit;
	}

	ngOnInit(): void {
		this.getCategories();
		this.getBrands();
		this.getSuppliers();
		if (this.productId) {
			this.getProductDetails();
		}
		this.getTaxPercent();
	}

	productVariantGroup(): FormGroup {
		return this.formBuilder.group({
			variant_name: new FormControl('', [Validators.required]),
			// variant_description: new FormControl('', [Validators.required]),
			variant_unit_cost: new FormControl('', [Validators.required]),
			variant_quantity: new FormControl('', [Validators.required]),
			product_price: new FormControl('', [Validators.required]),
			tax_amount: new FormControl(''),
			price_with_tax: new FormControl('', [Validators.required]),
			discount_price_with_tax: new FormControl('', [Validators.required]),
			product_discount_percentage: new FormControl(''),
			product_discount_price: new FormControl(''),
			variant_weight_gram: new FormControl('', [Validators.required]),
			variant_weight_oz: new FormControl('', [Validators.required]),
			variant_unit_type: new FormControl(ProductCostUnit.PERCENT, [Validators.required]),
			cogs: new FormControl('', [Validators.required]),
		});
	}

	calculateDiscountedPrice(index: number) {
		const { product_price, product_discount_percentage } =
			this.productForm.get('productVariant').value[index];

		if (product_price && product_discount_percentage) {
			const discountedAmount = (
				(Number(product_price) * Number(product_discount_percentage)) /
				100
			).toFixed(2);
			const discountedPrice = Number(product_price) - Number(discountedAmount);

			let existingFormValues = this.productForm.get('productVariant').value;
			existingFormValues[index].product_discount_price = discountedPrice;
			this.productForm.get('productVariant').patchValue(existingFormValues, {
				emitEvent: false,
			});
		}
	}

	calculateWeightOz(index: number) {
		const { variant_weight_gram } = this.productForm.get('productVariant').value[index];
		if (variant_weight_gram) {
			const weightOz = (Number(variant_weight_gram) * this.GRAM_TO_OZ).toFixed(2);
			let existingFormValues = this.productForm.get('productVariant').value;
			existingFormValues[index].variant_weight_oz = weightOz;
			this.productForm.get('productVariant').patchValue(existingFormValues, {
				emitEvent: false,
			});
		}
	}

	getCategories() {
		this.commonApiCall.getCategories().subscribe({
			next: (res) => {
				this.categories = res;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	getBrands() {
		this.http.get(Api.BRANDS).subscribe({
			next: (res) => {
				this.brands = res;
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	get variantArray(): UntypedFormArray {
		return <UntypedFormArray>this.productForm.get('productVariant');
	}

	addVariant(): void {
		this.variantArray.push(this.productVariantGroup());
	}

	removeVariant(index: number): void {
		const variantId = this.variantArray.value[index].id;
		this.http.delete(Api.DELETE_PRODUCT_VARIANT + variantId).subscribe({
			next: (res: any) => {
				this.variantArray.removeAt(index);
				this.utilityService.showSuccessToast('Product variant deleted.');
			},
			error: (err: any) => {
				this.utilityService.showSuccessToast('Unable to delete product variant');
			},
		});
	}

	getProductDetails() {
		this.http.get(Api.PRODUCTS + this.productId).subscribe({
			next: (res) => {
				this.productData = res;
				let existingVariants = [];
				for (const variant of this.productData.product_variant) {
					const discountedAmount =
						(Number(variant.product_price) *
							Number(variant.product_discount_percentage)) /
						100;
					const discountedPrice = (
						Number(variant.product_price) - Number(discountedAmount)
					).toFixed(2);

					existingVariants.push(
						this.formBuilder.group({
							id: variant.id,
							variant_name: variant.variant_name,
							//   variant_description: variant.variant_description,
							variant_unit_cost: variant.variant_unit_cost,
							variant_quantity: variant.variant_quantity,
							product_price: variant.product_price,
							tax_amount: variant.tax_amount,
							price_with_tax: variant.price_with_tax,
							discount_price_with_tax: variant.discount_price_with_tax,
							product_discount_percentage: variant.product_discount_percentage,
							product_discount_price: discountedPrice,
							variant_weight_gram: variant.variant_weight_gram,
							variant_weight_oz: variant.variant_weight_oz,
							variant_unit_type: variant.variant_unit_type,
							cogs: variant.cogs,
						}),
					);
				}

				const _productCountry = this.countries.find(
					(country) => country.code == this.productData.country,
				);
				console.log('_productCountry', _productCountry);

				this.productForm.patchValue(
					{
						productName: this.productData.product_name,
						productDescription: this.productData.product_description,
						productLongDescription: this.productData.product_long_description,
						category_id: this.productData.category_id,
						brand_id: this.productData.brand_id,
						supplier_id: this.productData.supplier_id,
						grow_type: this.productData.grow_type,
						flowering: this.productData.flowering,
						climate: this.productData.climate,
						landrace: this.productData.landrace,
						domesticated: this.productData.domesticated,
						tissue_culture: this.productData.tissue_culture,
						chemovars: this.productData.chemovars,
						thc: this.productData.thc,
						cbd: this.productData.cbd,
						cbda: this.productData.cbda,
						cbg: this.productData.cbg,
						cbga: this.productData.cbga,
						cbc: this.productData.cbc,
						thcp: this.productData.thcp,
						thcv: this.productData.thcv,
						cbdv: this.productData.cbdv,
						cbn: this.productData.cbn,
						status: this.productData.status == '1' ? true : false,
						quality_tested: this.productData.quality_tested == '1' ? true : false,
						harvest_date_by_latitude: this.productData.harvest_date_by_latitude,
						allow_pre_order: this.productData.allow_pre_order == '1' ? true : false,
						country: _productCountry,
					},
					{
						onlySelf: true,
					},
				);

				this.productForm.setControl(
					'productVariant',
					this.formBuilder.array(existingVariants),
				);

				if (this.productData.product_images && this.productData.product_images_id) {
					let _existingProductImages = this.productData.product_images.split(',');
					let _existingProductImagesId = this.productData.product_images_id.split(',');

					this.existingProductImages = [];

					for (const i in _existingProductImages) {
						this.existingProductImages.push({
							image: _existingProductImages[i],
							imageId: _existingProductImagesId[i],
						});
					}
				}
				this.existingProductMainImage = this.productData.product_main_image;
				this.existingProductGrowReport = this.productData.grow_report;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	onProductMainImageSelect(event) {
		this.selectedMainImage = event.target.files[0];
		if (event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (_event) => {
				this.productMainImagePreview = reader.result;
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	}

	onImagesSelect(event) {
		this.isNewImageSelected = true;
		this.selectedItemImages = [...this.selectedItemImages, ...event.target.files];
		if (this.selectedItemImages.length + this.existingProductImages.length > 4) {
			this.utilityService.showInfoToast('Maximum 4 images are allowed');
		} else {
			const numberOfFiles = event.target.files.length;
			for (let i = 0; i < numberOfFiles; i++) {
				const reader = new FileReader();
				reader.onload = (_event) => {
					this.imagePreviews.push(reader.result);
				};
				reader.readAsDataURL(event.target.files[i]);
			}
		}
	}

	removeMainImage() {
		if (this.existingProductMainImage && !this.productMainImagePreview) {
			this.existingProductMainImage = '';
			this.productData.product_main_image = '';
		} else if (this.productMainImagePreview) {
			this.productMainImagePreview = null;
			this.selectedMainImage = [];
		}
	}

	removeImage(index: number) {
		this.imagePreviews.splice(index, 1);
		this.selectedItemImages.splice(index, 1);
	}

	removeExistingImage(index: number, imageId) {
		this.existingProductImages.splice(index, 1);
		this.http.delete(Api.DELETE_PRODUCT_IMAGE(imageId)).subscribe({
			next: (res: any) => {
				this.utilityService.hideLoading();
				this.utilityService.showSuccessToast(ApiMessages.PRODUCT_IMAGE_REMOVED);
			},
			error: (err: any) => {
				console.log(err);
				this.utilityService.hideLoading();
			},
		});
	}

	saveProductData() {
		if (this.productId) {
			this.updateProductData();
		} else {
			this.addProduct();
		}
	}

	addProduct() {
		try {
			if (this.productForm.valid) {
				if (!this.selectedMainImage) {
					throw 'Product Main image is required';
				} else if (this.selectedItemImages.length == 0) {
					throw 'Product images are required';
				} else {
					this.utilityService.showLoading();
					let {
						category_id,
						brand_id,
						supplier_id,
						productName,
						productDescription,
						productLongDescription,
						productVariant,
						grow_type,
						flowering,
						climate,
						landrace,
						domesticated,
						tissue_culture,
						chemovars,
						thc,
						cbd,
						cbda,
						cbg,
						cbga,
						cbc,
						thcp,
						thcv,
						cbdv,
						cbn,
						// thca,
						status,
						quality_tested,
						allow_pre_order,
						country,
						product_for,
					} = this.productForm.value;
					debugger;
					// for (const index in productVariant) {
					//   delete productVariant[index].product_discount_price;
					// }

					const _formData: any = new FormData();
					for (const img of this.selectedItemImages) {
						_formData.append('images', img, img.name);
					}

					if (this.selectedMainImage && this.selectedMainImage.name) {
						_formData.append(
							'product_main_image',
							this.selectedMainImage,
							this.selectedMainImage.name,
						);
					}

					if (this.selectedProductGrowReport && this.selectedProductGrowReport.name) {
						_formData.append(
							'grow_report',
							this.selectedProductGrowReport,
							this.selectedProductGrowReport.name,
						);
					}

					_formData.append('category_id', category_id);
					_formData.append('brand_id', brand_id);
					_formData.append('supplier_id', supplier_id);
					_formData.append('product_name', productName);
					_formData.append('product_description', productDescription);
					_formData.append('product_long_description', productLongDescription);
					_formData.append('product_variant', JSON.stringify(productVariant));
					_formData.append('grow_type', grow_type);
					_formData.append('flowering', flowering);
					_formData.append('climate', climate);
					_formData.append('landrace', landrace);
					_formData.append('domesticated', domesticated);
					_formData.append('tissue_culture', tissue_culture);
					_formData.append('chemovars', chemovars);
					_formData.append('thc', thc);
					_formData.append('cbd', cbd);
					_formData.append('cbda', cbda);
					_formData.append('cbg', cbg);
					_formData.append('cbga', cbga);
					_formData.append('cbc', cbc);
					_formData.append('thcp', thcp);
					_formData.append('thcv', thcv);
					_formData.append('cbdv', cbdv);
					_formData.append('cbn', cbn);
					// _formData.append('thca', thca);
					_formData.append('status', status == true ? '1' : '0');
					_formData.append('quality_tested', quality_tested == true ? '1' : '0');
					_formData.append('allow_pre_order', allow_pre_order == true ? '1' : '0');
					_formData.append('country', country.code);
					_formData.append('currency', country.currency);
					_formData.append('product_for', product_for);

					this.http.post(Api.ADD_PRODUCT, _formData).subscribe({
						next: (res: any) => {
							this.productForm.reset();
							this.productForm.clearValidators();
							this.utilityService.hideLoading();
							this.utilityService.showSuccessToast(ApiMessages.PRODUCT_ADDED);
							this.selectedItemImages = [];
							this.imagePreviews = [];
							this.selectedMainImage = null;
							this.productMainImagePreview = null;
						},
						error: (err: any) => {
							console.log(err);
							this.utilityService.hideLoading();
							this, this.utilityService.showErrorToast(ApiMessages.PRODUCT_ADD_ERROR);
						},
						complete: () => {},
					});
				}
			} else {
				throw 'Some product details are invalid';
			}
		} catch (error) {
			this.utilityService.hideLoading();
			this.utilityService.showErrorToast(error);
		}
	}

	updateProductData() {
		try {
			if (this.productForm.valid) {
				if (
					this.selectedMainImage &&
					this.selectedMainImage.length == 0 &&
					!this.productData.product_main_image
				) {
					throw 'Product Main image is required';
				} else if (
					this.selectedItemImages.length == 0 &&
					this.existingProductImages.length == 0
				) {
					throw 'Product images are required';
				} else {
					this.utilityService.showLoading();
					let {
						category_id,
						brand_id,
						supplier_id,
						productName,
						productDescription,
						productLongDescription,
						productVariant,
						grow_type,
						flowering,
						climate,
						landrace,
						domesticated,
						tissue_culture,
						chemovars,
						thc,
						cbd,
						cbda,
						cbg,
						cbga,
						cbc,
						thcp,
						thcv,
						cbdv,
						cbn,
						// thca,
						status,
						quality_tested,
						harvest_date_by_latitude,
						allow_pre_order,
						country,
						product_for,
					} = this.productForm.value;
					debugger;
					// for (const index in productVariant) {
					//   delete productVariant[index].product_discount_price;
					// }

					const _formData: any = new FormData();

					if (this.isNewImageSelected && this.selectedItemImages.length > 0) {
						for (const img of this.selectedItemImages) {
							_formData.append('images', img, img.name);
						}
					}

					if (this.selectedMainImage && this.selectedMainImage.name) {
						_formData.append(
							'product_main_image',
							this.selectedMainImage,
							this.selectedMainImage.name,
						);
					} else {
						_formData.append('product_main_image', this.productData.product_main_image);
					}

					if (this.selectedProductGrowReport && this.selectedProductGrowReport.name) {
						_formData.append(
							'grow_report',
							this.selectedProductGrowReport,
							this.selectedProductGrowReport.name,
						);
					} else if (this.productData && this.productData.grow_report) {
						_formData.append('grow_report', this.productData.grow_report);
					}

					_formData.append('category_id', category_id);
					_formData.append('brand_id', brand_id);
					_formData.append('supplier_id', supplier_id);
					_formData.append('product_name', productName);
					_formData.append('product_description', productDescription);
					_formData.append('product_long_description', productLongDescription);
					_formData.append('product_variant', JSON.stringify(productVariant));
					_formData.append('grow_type', grow_type);
					_formData.append('flowering', flowering);
					_formData.append('climate', climate);
					_formData.append('landrace', landrace);
					_formData.append('domesticated', domesticated);
					_formData.append('tissue_culture', tissue_culture);
					_formData.append('chemovars', chemovars);
					_formData.append('thc', thc);
					_formData.append('cbd', cbd);
					_formData.append('cbda', cbda);
					_formData.append('cbg', cbg);
					_formData.append('cbga', cbga);
					_formData.append('cbc', cbc);
					_formData.append('thcp', thcp);
					_formData.append('thcv', thcv);
					_formData.append('cbdv', cbdv);
					_formData.append('cbn', cbn);
					_formData.append('status', status == true ? '1' : '0');
					_formData.append('quality_tested', quality_tested == true ? '1' : '0');
					_formData.append('harvest_date_by_latitude', harvest_date_by_latitude);
					_formData.append('allow_pre_order', allow_pre_order == true ? '1' : '0');
					_formData.append('country', country.code);
					_formData.append('currency', country.currency);
					_formData.append('product_for', product_for);

					this.http.patch(Api.PRODUCTS + this.productId, _formData).subscribe({
						next: (res: any) => {
							this.utilityService.hideLoading();
							this.utilityService.showSuccessToast(ApiMessages.PRODUCT_UPDATED);
							this.getProductDetails();
							this.selectedItemImages = [];
							this.imagePreviews = [];
							this.selectedMainImage = null;
							this.productMainImagePreview = null;
							this.selectedProductGrowReport = null;
						},
						error: (err: any) => {
							console.log(err);
							this.utilityService.hideLoading();
							this.utilityService.showErrorToast(ApiMessages.PRODUCT_UPDATE_ERROR);
						},
					});
				}
			} else {
				throw 'Some product details are invalid.';
			}
		} catch (error) {
			this.utilityService.hideLoading();
			this.utilityService.showErrorToast(error);
		}
	}

	getSuppliers() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_SUPPLIERS).subscribe({
			next: (res) => {
				this.suppliers = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	calculateCOGS(index: number) {
		const {
			variant_unit_type,
			variant_unit_cost,
			product_discount_percentage,
			product_discount_price,
		} = this.productForm.get('productVariant').value[index];

		if (variant_unit_cost) {
			if (variant_unit_type == ProductCostUnit.AMOUNT) {
				const calculatedCogs = (
					Number(variant_unit_cost) *
					((100 - product_discount_percentage) / 100)
				).toFixed(2);
				let existingFormValues = this.productForm.get('productVariant').value;
				existingFormValues[index].cogs = calculatedCogs;
				this.productForm.get('productVariant').patchValue(existingFormValues, {
					emitEvent: false,
				});
			} else if (variant_unit_type == ProductCostUnit.PERCENT) {
				const calculatedCogs = (
					Number(product_discount_price) *
					(Number(variant_unit_cost) / 100)
				).toFixed(2);
				let existingFormValues = this.productForm.get('productVariant').value;
				existingFormValues[index].cogs = calculatedCogs;
				this.productForm.get('productVariant').patchValue(existingFormValues, {
					emitEvent: false,
				});
			}
		}
	}

	getTaxPercent() {
		this.http.get(Api.GET_TAX_PERCENT).subscribe({
			next: (res: any) => {
				this.taxPercent = Number(res[0].tax);
			},
			error: (err: any) => {},
		});
	}

	calculatePriceWithTax(index: number) {
		const { product_price, product_discount_percentage, product_discount_price } =
			this.productForm.get('productVariant').value[index];
		let taxAmount = 0;
		let priceWithTax = 0;
		let discountPriceWithTax = 0;

		if (product_price) {
			taxAmount = Number(product_price) * (this.taxPercent / 100);
			priceWithTax = Number(product_price) + taxAmount;
		}

		if (product_discount_percentage && product_discount_price) {
			taxAmount = Number(product_discount_price) * (this.taxPercent / 100);
			discountPriceWithTax = Number(product_discount_price) + taxAmount;
		}

		let existingFormValues = this.productForm.get('productVariant').value;
		existingFormValues[index].tax_amount = taxAmount;
		existingFormValues[index].price_with_tax = priceWithTax;
		existingFormValues[index].discount_price_with_tax = discountPriceWithTax;

		this.productForm.get('productVariant').patchValue(existingFormValues, {
			emitEvent: false,
		});
	}

	onGrowReportSelect(event) {
		this.selectedProductGrowReport = event.target.files[0];
		if (event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (_event) => {
				this.productGrowReportPreview = reader.result;
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	}

	removeGrowReport() {
		if (this.existingProductGrowReport && !this.productGrowReportPreview) {
			this.existingProductGrowReport = '';
			this.productData.grow_report = '';
		} else if (this.productGrowReportPreview) {
			this.productGrowReportPreview = null;
			this.selectedProductGrowReport = [];
		}
	}
}
