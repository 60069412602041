<div class="page">
	<form [formGroup]="categoryFrom" (ngSubmit)="submitCategoryData()">
		<div class="flex flex-col gap-4">
			<app-field-with-label title="Forum Category Name" [required]="true">
				<input pInputText type="text" name="forum_category_name" placeholder="Forum Category Name"
					formControlName="forum_category_name" required />
				<span
					*ngIf="forum_category_name.invalid && (forum_category_name.dirty || forum_category_name?.touched)">
					<span *ngIf="forum_category_name?.errors?.['required']"> Category name is required </span>
				</span>
			</app-field-with-label>

			<app-field-with-label title="Category Code" [required]="true">
				<input pInputText type="text" name="forum_category_color" placeholder="Category Code"
					formControlName="forum_category_color" required />

				<span
					*ngIf="forum_category_color.invalid && (forum_category_color.dirty || forum_category_color?.touched)">
					<span *ngIf="forum_category_color?.errors?.['required']"> Category Color Code is required </span>
				</span>
			</app-field-with-label>

			<div class="flex items-center justify-end">
				<button pButton type="submit">Save Category</button>
			</div>
		</div>
	</form>
</div>