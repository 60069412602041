export enum FieldsType {
	Image = 'Image',
	Action = 'Action',
	Text = 'text',
	LinkButton = 'linkButton',
	Number = 'number',
	Email = 'email',
	Telephone = 'tel',
	DropDown = 'dropdown',
	DropDownWithImage = 'dropDownWithImage',
	AutoComplete = 'autoComplete',
	Date = 'date',
	DaysAgo = 'daysAgo',
	Boolean = 'boolean',
	ImageGroup = 'imageGroup',
	Category = 'category',
	Toggle = 'toggle',
	Custom = 'custom',
	Currency = 'currency',
}
