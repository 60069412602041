import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InviteCodeComponent } from './invite-code/invite-code.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { RouterModule } from '@angular/router';
import { InviteCodeRoutingModule } from './invite-code-routing.module';
import { AddInviteCodeComponent } from './add-invite-code/add-invite-code.component';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { FieldWithLabelComponent } from 'src/app/components/field-with-label/field-with-label.component';
@NgModule({
	declarations: [InviteCodeComponent, AddInviteCodeComponent],
	imports: [
		CommonModule,
		ComponentsModule,
		RouterModule.forChild(InviteCodeRoutingModule),
		PageHeaderComponent,
		SimpleTableComponent,
		ReactiveFormsModule,
		CalendarModule,
		InputTextModule,
		FieldWithLabelComponent,
	],
})
export class InviteCodeModule {}
