import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { DialogAction } from 'src/app/enums/dialog-action';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-manage-faq',
	templateUrl: './manage-faq.component.html',
	styleUrls: ['./manage-faq.component.scss'],
})
export class ManageFaqComponent implements OnInit {
	dialogAction: DialogAction;
	faqFrom: FormGroup;
	faqData: any;
	categories = [];
	public editor: any = ClassicEditor;
	constructor(
		private utility: UtilityService,
		private http: HttpService,
		private ref: DynamicDialogRef,
		private dialogConfig: DynamicDialogConfig,
	) {
		this.getFaqCategories();
	}

	ngOnInit(): void {
		this.faqFrom = new FormGroup({
			title: new FormControl('', [Validators.required]),
			description: new FormControl('', [Validators.required]),
			category: new FormControl('', [Validators.required]),
		});
		const { type, faqData } = this.dialogConfig.data;

		this.dialogAction = type;
		if (this.dialogAction == DialogAction.EDIT) {
			this.faqData = faqData;
			this.faqFrom.patchValue({
				title: this.faqData.title,
				description: this.faqData.description,
				category: Number(this.faqData.category),
			});
		}
	}

	get title() {
		return this.faqFrom.get('title');
	}

	get description() {
		return this.faqFrom.get('description');
	}

	get category() {
		return this.faqFrom.get('category');
	}

	getFaqCategories() {
		this.http.get(Api.FAQ_CATEGORIES).subscribe({
			next: (res: any) => {
				this.categories = res;
			},
			error: (err: any) => {
				console.log(err);
			},
			complete: () => {
				this.utility.hideLoading();
			},
		});
	}

	submitFaqData() {
		if (this.dialogAction == DialogAction.ADD) {
			this.addFaq();
		} else if (this.dialogAction == DialogAction.EDIT) {
			this.editFaq();
		}
	}

	addFaq() {
		if (this.faqFrom.valid) {
			this.utility.showLoading();
			let params = this.faqFrom.value;
			this.http.post(Api.ADD_FAQ, params).subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('FAQ Added.');
					this.faqFrom.reset();
				},
				error: (err: any) => {
					console.log(err);
					this.utility.showSuccessToast('Unable to add FAQ.');
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showSuccessToast('Please check faq details.');
		}
	}

	editFaq() {
		if (this.faqFrom.valid) {
			this.utility.showLoading();
			let params = this.faqFrom.value;
			this.http.patch(Api.UPDATE_FAQ(this.faqData.id), params).subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('FAQ Updated.');
					this.closeDialog();
				},
				error: (err: any) => {
					console.log(err);
					this.utility.showSuccessToast('Unable to update FAQ.');
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showSuccessToast('Please check FAQ details.');
		}
	}

	closeDialog() {
		this.ref.close();
	}
}
