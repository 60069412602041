import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UserService } from 'src/app/services/user/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
	signInFrom: UntypedFormGroup;

	constructor(
		private utility: UtilityService,
		private http: HttpService,
		private router: Router,
		public userService: UserService,
	) { }

	ngOnInit(): void {
		this.signInFrom = new UntypedFormGroup({
			email: new UntypedFormControl('', [Validators.required, Validators.email]),
			password: new UntypedFormControl('', [Validators.required]),
		});
	}

	get email() {
		return this.signInFrom.get('email');
	}
	get password() {
		return this.signInFrom.get('password');
	}

	signIn() {
		if (this.signInFrom.valid) {
			this.utility.showLoading();
			let params = {
				email: this.signInFrom.value.email,
				password: this.signInFrom.value.password,
			};
			this.http.post(Api.SIGN_IN, params).subscribe({
				next: (res: any) => {
					this.userService.setAccessToken(res.token);
					this.getUserData();
					this.router.navigate(['/home']);
				},
				error: (err) => {
					this.utility.hideLoading();
					this.utility.showErrorToast(
						'Invalid email or password. Please check your details.',
					);
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showErrorToast('Invalid email or password. Please check your details.');
		}
	}

	getUserData() {
		this.http.get(Api.GET_DETAILS).subscribe({
			next: (res: any) => {
				this.userService.user = res;
				this.userService.setUserData(res);
			},
			error: (err: any) => {
				console.log(err);
			},
		});
	}
}
