import { Component, OnInit, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
@Component({
	selector: 'app-topic-moderator',
	templateUrl: './topic-moderator.component.html',
	styleUrls: ['./topic-moderator.component.scss'],
})
export class TopicModeratorComponent implements OnInit {
	categoryName: string;
	categoryId: string;
	selectedTopicUsers: any[] = [];
	topicUsers: any[] = [];
	topicUsersCopy: any[] = [];
	constructor(
		private utility: UtilityService,
		private http: HttpService,
		private ref: DynamicDialogRef,
		private dialogConfig: DynamicDialogConfig,
	) {
	}

	ngOnInit(): void {
		const { categoryName, categoryId } = this.dialogConfig.data;
		this.categoryName = categoryName;
		this.categoryId = categoryId;
		this.getAllUsers();
		this.getExistingModerators();
	}

	getExistingModerators() {
		this.http.get(Api.GET_FORUM_MODERATOR_FOR_CATEGORY(this.categoryId)).subscribe({
			next: (res: any) => {
				this.selectedTopicUsers = res;
			},
			error: (err: any) => {
				console.log(err);
			},
			complete: () => { },
		});
	}

	getAllUsers() {
		this.http.get(Api.GET_CONSUMERS).subscribe({
			next: (res: any) => {
				this.topicUsers = res;
				this.topicUsersCopy = res;

			},
			error: (err: any) => {
				console.log(err);
			},
			complete: () => { },
		});
	}

	closeDialog() {
		this.ref.close();
	}

	saveModerator() {
		this.utility.showLoading();
		const moderatorsId = this.selectedTopicUsers.map((user: any) => user.id);
		const params = {
			category_id: this.categoryId,
			moderator_ids: moderatorsId,
		};
		this.http.post(Api.FORUM_MODERATOR_FOR_CATEGORY, params).subscribe({
			next: (res: any) => {
				this.utility.showSuccessToast('Forum moderator saved.');
				this.closeDialog();
				this.utility.hideLoading();
			},
			error: (err: any) => {
				console.log(err);
				this.utility.showSuccessToast('Unable to save moderator.');
				this.utility.hideLoading();
			},
			complete: () => {
				this.utility.hideLoading();
			},
		});
	}

	searchUser(event: AutoCompleteCompleteEvent) {
		let filtered: any[] = this.topicUsersCopy.filter((user: any) => {
			return user.email.toLowerCase().indexOf(event.query.toLowerCase()) > -1
		})
		this.topicUsers = filtered;
	}
}
