import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/interfaces/user';
import { UserService } from 'src/app/services/user/user.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	user: User;
	greetings: string;

	constructor(private userService: UserService) {}

	ngOnInit(): void {
		this.userService.getUserData();
		this.user = this.userService.user;
		this.getGreetings();
	}

	private getGreetings() {
		const time = new Date().getHours();
		if (time < 12) {
			this.greetings = 'Good morning!';
		} else if (time < 18) {
			this.greetings = 'Good afternoon!';
		} else {
			this.greetings = 'Good evening';
		}
	}
}
